@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700;800&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --purple: #7168D9;
  --darkPurple: #665BE1;
  --lightPurple: #B4AFF2;
  --white: #FFFFFF;
  --lightBlue: #CFEDEE;
  --pink: #EAD6F2;
  --transparentWhite: rgba(255, 255, 255, 0.2);
  --transparentPurple: rgba(180, 175, 242, 0.3);
}


body {
  margin: 0;
  font-family: "Archivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(150deg,#CFEDEE 20%,#EAD6F2 40%, #CFEDEE 60% );
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
